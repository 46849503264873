<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import SaveButton from "@/components/buttons/save-button";

export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        SaveButton
    },
    data() {
        return {
            title: 'General Settings',
            items: [
                {
                    text: "Settings"
                },
            ],
            dataRender: [],
            inputVal: {
                system_status: '',
                bypass_ip: ''
            },
            btn_clicked: false
        };
    },
    computed: {

    },
    watch: {

    },
    async created() {
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/general`, {
            headers: authHeader(),
        });
        if (result.status === 200) {
            if (result.data.data.length > 0) {
                const { data } = result.data;
                let dataFields = [];

                data.forEach(element => {
                    
                    let fields = [];
                    const id = element.id;
                    const title = element.title;
                    const description = element.description;
                    const mapFormat = {
                        "id": id,
                        "title": title,
                        "description": description,
                        "key": element.key,
                        "fields": fields,
                        "type": element.type,
                        "value": element.value
                    }
                    if (element.type === 'radio') {
                        this.inputVal.system_status = element.value;
                        let radio = JSON.parse(element.selection);
                        
                        Object.keys(radio).forEach((val) => {
                            let field = {
                                "value": val,
                                "label": radio[val]
                            };
                            fields.push(field);
                        });
                        dataFields.push(mapFormat);
                    } else if (element.type === 'textarea') {
                        // this.inputVal.bypass_ip = element.value;
                        // dataFields.push(mapFormat);          
                    }
                    this.dataRender = dataFields;
                });
            }
        }
    },
    async mounted() {
        
    },
    methods: {
        deleteRecord() {
        },

        setAdminView() {
            // this.filter = Object.assign(this.filter, filter);
        },

        filterTable() {
        },

        async save() {
            if (this.btn_clicked == true) return ;
            this.btn_clicked = true;
            
            const result = await axios.put(`${process.env.VUE_APP_APIURL}/general`, {
                "selection": [
                    {
                        "setting_group_id": 1,
                        "key": "system_status",
                        "value": this.inputVal.system_status
                    }, 
                ]
            }, {
                headers: authHeader(),
            }).catch(() => {
                this.btn_clicked = false;
            });
            if (result.data.code === 'success') {
                this.btn_clicked = false;
                this.$refs.messageModal.showModal('Your record has been updated successfully');
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <b-tabs content-class="" >
                        <b-tab title="General" active>
                            <div class="card">
                                <div class="card-body tab-content">
                                    <div class="form-group">
                                        <template v-if="dataRender.length != 0" >
                                            <div v-for="options in dataRender" :key="options.id" class=" row">
                                                <div class="col-md-6">
                                                    <div class="form-group row">
                                                        <p class="col-sm-5">{{ options.title }}</p>
                                                        <div class="col-sm-7">
                                                            <template v-if="options.type === 'radio' && options.fields.length > 0" > 
                                                                <div class="d-flex flex-wrap">  
                                                                    <div v-for="radioBtn in options.fields" :key="radioBtn.value" class="d-flex">
                                                                        <input type="radio" :id="radioBtn.value" v-model="inputVal.system_status" :name="options.key" :value="radioBtn.value">  
                                                                        <label class="mb-0 ml-2 mr-3" :for="radioBtn.value">{{ radioBtn.label }}</label>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                            <template v-else-if="options.type === 'textarea'">
                                                                <textarea :name="options.key" cols="30" rows="5" v-model="inputVal.bypass_ip"></textarea>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>{{ options.description }}</p>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <SaveButton @click="save" :disabled="btn_clicked"/>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </Layout>
</template>